.navbarIcons {
    color: white;
    cursor: pointer;
    margin-top: 6%;
}

.navButtonContainer {
    display: inline-block;
    margin-left: 10;
    margin-right: 10;
    margin-top: 6%;
}

.pageCountText {
    display: inline-block;
    color: white;
    font-size: 15;
    margin-top: 6%;
}

.zoomButtonContainer {
    display: inline-block;
    margin-top: 6%;
}

.containerNavbar {
    align-items: stretch;
    display: flex;
    padding: 0;
    justify-content: center;
    background-color: #1976d2;
}