.article {
    padding-bottom: 10%;
    padding-top: 3%;
    width: 100%;
    height: 100vh;
}

.article img {
    width: 70%;
}

.emailText {
    color: "blue";
    padding-left: "2%";
    padding-bottom: "5%";
}